import {
	Grid,
	Box,
	Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import {useShipmentDetails} from "../../context/ShipmentDetailsContext";

import {
	RadioButtonCheckedOutlined
} from "@mui/icons-material";

export function TrackingDetails() {

	const { tracking } = useShipmentDetails();

	const theme = useTheme();

	const TRACKING_LAST_ELEMENT_INDEX = tracking.length - 1;

	const renderDivider = () => (
		<div style={{width: 1, height: '100%', backgroundColor: '#CECECE'}} />
	);

	const renderItem = (item, index) => {
		return (
		
		<Grid key={index} container sx={{ paddingBottom: '0.5rem'}}>

			<Grid sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: 4, [theme.breakpoints.down('sm')]: {
          display: 'none', 
      }}} >
				<RadioButtonCheckedOutlined fontSize={"0.4rem"} color="primary" sx={{marginBottom: 1, paddingTop: '0.2rem'}} />
				{index !== TRACKING_LAST_ELEMENT_INDEX && renderDivider()}
			</Grid>


			<Grid>
				<Typography sx={{color: "text.secondary"}} fontSize={"0.9rem"}>{item.date}</Typography>

				<Typography fontWeight="600" fontSize={"0.9rem"}>{item.message}</Typography>

				<Typography fontSize={"0.8rem"}>{item.location}</Typography>

			</Grid>
		</Grid>
	)};


	const renderTrackingStatus = () => (
		tracking.map((item, index) => (
			renderItem(item, index)
		))
	);

	const renderEmptyList = () => (
		<Typography variant="body1" sx={{color: "text.secondary"}}>
			No tracking updates yet.
		</Typography>
	);

	return (
		<Box sx={{ padding: '1rem'}}>
			{tracking.length > 0 ? renderTrackingStatus() : renderEmptyList()}
		</Box>
	);
}
