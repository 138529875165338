import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { Controller, useFormContext } from "react-hook-form";

import { Typography, Stack, Box, Grid, MenuItem } from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import dayjs from "dayjs";
import { LocationSearchField } from "./LocationSearchField/LocationsSearchField";
import { useEffect, useState } from "react";

import { getDayjsDate } from "src/utils/formatTime";
import { shipmentServices } from "../../constants/services";
import { ServiceField } from "./ServicesFields";
import { LimitedAccessField } from "./LimitedAccessField";

import { usaStates } from "../../../warehouses/constants/usaStates";
import { countries } from "../../constants/countries";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { defaultTimezone, timezones } from "src/utils/timezones";
import { useAuth } from "src/hooks/useAuth";
import { mexicoStates } from "src/pages/warehouses/constants/mexicoState";
import { useTenant } from "src/hooks/useTenant";

export function StopAddressFields({ itemIndex = 0 }) {
  const {
    control,
    formState: { errors },
    getValues, setValue,
    watch,
  } = useFormContext();

  const [minEarlyDate, setMinEarlyDate] = useState(dayjs());
  const [minLateDate, setMinLateDate] = useState(dayjs());
  const [tz, setTz] = useState(null);

  const { session } = useAuth();
  const { tenant } = useTenant();

  useEffect(() => {
    setMinEarlyDate(
      getDayjsDate(getValues(`pickups.${itemIndex}.targetShipEarly`))
    );
    setMinLateDate(
      getDayjsDate(getValues(`pickups.${itemIndex}.targetShipEarly`))
    );
  }, [watch(`pickups.${itemIndex}.targetShipEarly`)]);

  useEffect(() => {
    setMinLateDate(
      getDayjsDate(getValues(`stops.${itemIndex}.targetDeliveryEarly`))
    );
  }, [watch(`stops.${itemIndex}.targetDeliveryEarly`)]);

  useEffect(() => {
    const timezone = getValues(`stops.${itemIndex}.timezone`);
    if (timezone) setTz(
      timezones.find(
        (t) => t.name === (timezone || defaultTimezone)
      )
    );
  }, [watch(`stops.${itemIndex}.timezone`)]);

  const getStates = () => {
    const country = getValues(`stops.${itemIndex}.destCtry`);
    if (country === 'CA') {
      return canadaStates;
    } else if (country === 'MX') {
      return mexicoStates;
    } else {
      return usaStates;
    }
  }

  const [states, setStates] = useState(getStates());

  useEffect(() => {
    setStates(getStates());

  }, [watch(`stops.${itemIndex}.destCtry`)]);

  useEffect(() => {
    if (!states.some(state => state.acronym == getValues(`stops.${itemIndex}.destState`)))
      setValue(`stops.${itemIndex}.destState`, '');

  }, [states]);

  return (
    <Stack spacing={3} width={"100%"}>
      <Typography>Add Stop Address</Typography>

      <LocationSearchField name={`stops.${itemIndex}.destName`} />

      <FormInput
        name={`stops.${itemIndex}.destAddr1`}
        label="Address 1"
        type="text"
      />
      <FormInput
        name={`stops.${itemIndex}.destAddr2`}
        label="Address 2"
        type="text"
      />
      <FormInput
        name={`stops.${itemIndex}.destCity`}
        label="City"
        type="text"
        required
      />
      <FormInput
        name={`stops.${itemIndex}.destState`}
        label="State"
        select
        required
      >
        {states.map((state) => {
          return (
            <MenuItem key={state.acronym} value={state.acronym}>
              {state.name}
            </MenuItem>
          );
        })}
      </FormInput>
      <FormInput
        name={`stops.${itemIndex}.destCtry`}
        label="Country"
        select
        required
      >
        {countries.map((state) => {
          return (
            <MenuItem key={state.acronym} value={state.acronym}>
              {state.name}
            </MenuItem>
          );
        })}
      </FormInput>
      <FormInput
        name={`stops.${itemIndex}.destZip`}
        label="Zip"
        type="text"
        required
      />
      <FormInput
        name={`stops.${itemIndex}.destContact`}
        label="Contact"
        type="text"
        required
      />
      <FormInput
        name={`stops.${itemIndex}.destPhone`}
        label="Phone"
        type="text"
        required
      />
      <FormInput
        name={`stops.${itemIndex}.destEmail`}
        label="E-Mail"
        type="text"
        required
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Controller
          control={control}
          name={`stops.${itemIndex}.targetDeliveryEarly`}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <DateTimePicker
              {...field}
              value={getDayjsDate(field.value)}
              onChange={(date) => {
                field.onChange(date);
              }}
              timezone="UTC"
              minDate={minEarlyDate}
              inputRef={ref}
              size="small"
              sx={{ width: "100%" }}
              label="Target Delivery Early"
            />
          )}
        />
        <Controller
          control={control}
          name={`stops.${itemIndex}.targetDeliveryLate`}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <DateTimePicker
              {...field}
              value={getDayjsDate(field.value)}
              onChange={(date) => {
                field.onChange(date);
              }}
              timezone="UTC"
              minDate={minLateDate}
              inputRef={ref}
              size="small"
              sx={{ width: "100%" }}
              label="Target Delivery Late"
            />
          )}
        />
        {tz && (
          <Typography component="h6" sx={{ mt: 1 }}>
            Timezone: {tz.name}{" "}
            <br />
            <small>{tz.rawFormat}</small>
          </Typography>
        )}
      </Box>
      <Box >
        <Typography fontWeight={600} pb={2}>Services</Typography>
        <Grid container columns={{ xs: 3 }} rowSpacing={1}>
          {shipmentServices
            .find((servicePerMode) => servicePerMode.mode == watch('mode'))
            ?.stops.map((service, index) => {
              return (
                <ServiceField
                  service={service}
                  key={`${service.name}${index}`}
                />
              );
            })}
          <Grid item xs={12}>
            <LimitedAccessField type={"stop"} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
}
