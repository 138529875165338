import { z } from "zod";
import { SubItem } from "./subItem";
import { Hazardous } from "./hazardous";

export class Item {
  constructor(itemData) {
    this.itemId = itemData?.itemId;
    this.class = itemData?.class;
    this.nmfcCode = itemData?.nmfcCode;
    this.actualQty = itemData?.actualQty;
    this.actualQtyUOM = itemData?.actualQtyUOM;
    this.actualWeight = itemData?.actualWeight;
    this.actualWeightUOM = itemData?.actualWeightUOM;
    this.actualWeightCountType = itemData?.actualWeightCountType;
    this.actualLength = itemData?.actualLength;
    this.actualWidth = itemData?.actualWidth;
    this.actualHeight = itemData?.actualHeight;
    this.actualDimensionsUOM = itemData?.actualDimensionsUOM;
    this.actualValue = itemData?.actualValue;
    this.description = itemData?.description;
    this.manufacturerPartNumber = itemData?.manufacturerPartNumber;
    this.poNumber = itemData?.poNumber;
    this.subItems = itemData?.subItems
      ? itemData?.subItems.map((subItemData) => new SubItem(subItemData))
      : [];
    this.hazardous = itemData?.hazardous;
  }
  static get createSchema() {
    return z.object({
      itemId: z.string(),
      class: z.number(),
      nmfcCode: z.string(),
      actualQty: z.number(),
      actualQtyUOM: z.string(),

      plannedQty: z.number(),
      plannedQtyUOM: z.string(),

      actualWeight: z.number(),
      actualWeightUOM: z.string(),
      actualWeightCountType: z.string(),

      actualLength: z.number(),
      actualWidth: z.number(),
      actualHeight: z.number(),
      actualDimensionsUOM: z.string(),

      actualValue: z.number(),

      description: z.string(),
      manufacturerPartNumber: z.string(),

      hazardous: Hazardous.createSchema,
    });
  }
}

// TODO - PIECE COUNT
// TODO - PIECE COUNT UNIT
