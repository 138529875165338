import { Box, Typography } from "@mui/material";
import { is } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ShipmentService } from "src/api/shipment.service";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useShipmentQueries() {
  const { session } = useAuth();
  const service = new ShipmentService(session.token, session.tenant._id);
  const { setSnackbarMessage } = useSnackbar();

  const navigate = useNavigate();

  const filterRef = useRef({ initialValue: 'opened' });
  const {
    isLoading: isLoadingBackofficeShipments,
    isRefetching: isRefetchingBackofficeShipments,
    data: backofficeShipments,
    refetch: getShipments,
  } = useQuery(`backoffice-shipments`, {
    queryFn: async () => {
      return service.getBackofficeShipmentsByStatus(filterRef.current);
    },
    enabled: false
  });

  function getBackofficeShipments(currentStatus) {
    filterRef.current = currentStatus;
    getShipments();
  }

  const { mutate: createShipment, isLoading: isCreationLoading } = useMutation({
    mutationFn: (values) =>
      service
        .createShipment(values)
        .then((data) => {
          setSnackbarMessage({
            message: `New shipment created with Primary Reference: ${data.primaryReference}`,
            severity: "success",
          });

          navigate("/shipments");

          return data;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1}>Error creating Shipment</Typography>
                <Typography>{error.message}</Typography>
              </Box>
            ),
            severity: "error",
          });
        }),
  });

  const { mutate: updateShipment, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (shipment) =>
      service.updateShipment(shipment._id, shipment).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Shipment</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg} - {error.param}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message || "Error to update Shipment"}
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Shipment successfully updated!`,
          severity: "success",
        });
        navigate(`/shipments/${shipment._id}`);
        return data;
      }),
  });

  const { mutate: resetShipmentStatus, isLoading: isResettingShipmentLoading } =
    useMutation({
      mutationFn: ({ shipmentId }) =>
        service.resetShipmentStatus(shipmentId).then((data) => {
          if (data.errors || data.messages || data.message) {
            setSnackbarMessage({
              message: (
                <Box>
                  <Typography>Error resetting status</Typography>
                </Box>
              ),
              severity: "error",
            });
            return data;
          }
          setSnackbarMessage({
            message: `Shipment status updated!`,
            severity: "success",
          });
          return data;
        }),
    });

  const { mutate: deleteShipment, isLoading: isDeletionLoading } = useMutation({
    mutationFn: (shipmentId) => service.deleteShipment(shipmentId),
    onSuccess: (data) => {
      if (data.errors) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography>Error deleting Shipment</Typography>
              <ErrorMessage response={data} />
            </Box>
          ),
          severity: "error",
        });
      } else {
        setSnackbarMessage({
          message: `Shipment successfully deleted!`,
          severity: "success",
        });
      }

      return data;
    },
  });

  const {
    isLoading: isLoadingCombinedShipments,
    data: combinedShipments,
    refetch: getCombinedShipments,
  } = useQuery("combined-shipments", {
    queryFn: async () => {
      return service.getCombinedShipments();
    },
    enabled: false,
  });

  const {
    data: combinedShipment,
    isLoading: isCombining,
    mutate: combineShipments,
  } = useMutation({
    mutationFn: async ({ shipmentsIds, mode }) => {
      return service.createFromShipments(shipmentsIds, mode).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <ErrorMessage response={data} defaultMessage={"Error to Combine Shipment"} />
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Shipment successfully combined into ${data.primaryReference}!`,
          severity: "success",
        });
        // navigate(`/shipments/${shipment._id}`);
        return data;
      });
    },
    enabled: false,
  });

  // HANDLE FILES
  const {
    isLoading: isUploadLoading,
    mutate: uploadFile,
    data: updatedShipment,
    isSuccess: isUploadSuccess,
  } = useMutation({
    mutationFn: ({ shipmentId, file, fileType }) => {
      return service
        .uploadShipmentFile(shipmentId, file, fileType)
        .then((response) => {
          setSnackbarMessage({
            message: <Box> Success to upload {fileType} </Box>,
            severity: "success",
          });

          return response;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: (
              <Box>
                {" "}
                Error to upload {fileType}: {error.message}
              </Box>
            ),
            severity: "error",
          });
        });
    },
  });

  const {
    isLoading: isDeletingFile,
    mutate: deleteFile,
    isSuccess: isFileDeleted,
    data: onFileDeletedData,
  } = useMutation({
    mutationFn: ({ shipmentId, fileType }) => {
      return service
        .deleteShipmentFile(shipmentId, fileType)
        .then((response) => {
          setSnackbarMessage({
            message: <Box> Success to delete {fileType} </Box>,
            severity: "success",
          });

          return response;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: (
              <Box>
                {" "}
                Error to delete {fileType}: {error.message}
              </Box>
            ),
            severity: "error",
          });
        });
    },
  });

  const {
    isLoading: isFileImportLoading,
    mutate: importIntegrationFile,
    data: importIntegrationResponse,
    isSuccess: isIntegrationImportSuccess,
  } = useMutation({
    mutationFn: ({ shipmentId, loadId, fileType }) => {
      return service
        .importIntegrationFile(shipmentId, loadId, fileType)
        .then((response) => {
          setSnackbarMessage({
            message: `${fileType} imported successfully`,
            severity: "success",
          });

          return response;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: error.message,
            severity: "error",
          });
        });
    },
  });

  // LOGISTIC ACTIONS
  const { mutate: confirmShipment, isLoading: isConfirmationLoading } =
    useMutation({
      mutationFn: ({ shipmentId, loadId, quoteId, carrier, callback }) => {
        return service
          .confirmShipment(shipmentId, loadId, quoteId, carrier)
          .then((response) => {
            if (response.errors || response.messages || response.message) {
              setSnackbarMessage({
                message: (
                  <Box>
                    <Typography mb={1} fontWeight={600}>
                      Error to Confirm quotation
                    </Typography>
                    <ErrorMessage response={response} />
                  </Box>
                ),
                severity: "error",
              });
            } else callback(response);

            return response;
          });
      },
    });

  const {
    isLoading: isQuotationLoading,
    mutate: getCarrierQuotations,
    data: carrierQuotations,
  } = useMutation({
    mutationFn: ({ shipmentId, callback }) =>
      service.getCarrierQuotations(shipmentId).then((response) => {
        if (response.errors || response.messages || response.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1} fontWeight={600}>
                  Error to get Quotations
                </Typography>
                <ErrorMessage response={response} />
              </Box>
            ),
            severity: "error",
          });
        }
        if (response.length == 0) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1}>No Quotation Available</Typography>
              </Box>
            ),
            severity: "warning",
          });
        }
        if (response.length && response.length > 0) callback(response);
      }),
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  return {
    isLoadingBackofficeShipments,
    isRefetchingBackofficeShipments,
    backofficeShipments,
    getBackofficeShipments,

    isLoadingCombinedShipments,
    combinedShipments,
    getCombinedShipments,

    combineShipments,
    isCombining,
    combinedShipment,

    createShipment,
    isCreationLoading,

    resetShipmentStatus,
    isResettingShipmentLoading,

    updateShipment,
    isUpdateLoading,

    isUploadLoading,
    uploadFile,
    isUploadSuccess,
    updatedShipment,

    isDeletingFile,
    deleteFile,
    isFileDeleted,
    onFileDeletedData,

    isFileImportLoading,
    importIntegrationFile,
    importIntegrationResponse,
    isIntegrationImportSuccess,

    deleteShipment,
    isDeletionLoading,

    confirmShipment,
    isConfirmationLoading,

    isQuotationLoading,
    getCarrierQuotations,
    carrierQuotations,
  };
}
