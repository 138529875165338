import { Close, DeleteForever } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { LocationsDetails } from "./details-drawer/LocationsDetails";

export function CombineShipmentsModal({
  open,
  handleClose,
  shipments,
  handleRemove,
}) {
  const [mode, setMode] = useState();

  const { combineShipments } = useShipmentQueries();
  useEffect(()=> {
    console.log(mode)
  },[mode])

  function handleCombine() {
    if(['TL','LTL'].includes(mode)) 
      combineShipments({ shipmentsIds: shipments.map(shipment => shipment._id), mode })
    else
      alert('Select a valid mode');
  }

  return (
    <Modal
      open={open && shipments?.length}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <StyledPaper>
        <Typography
          variant="h1"
          sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" }, py: 2 }}
        >
          Shipments to Combine
        </Typography>
        <Stack gap={2}>
        <Stack gap={2}>
            <Typography
              variant="h2"
              sx={{ "&.MuiTypography-h2": { fontSize: "1rem" } }}
            >
              Select Combined Shipment Mode
            </Typography>
            <TextField
              select
              size="small"
              fullWidth
              label="Mode"
              defaultValue="SELECT"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
            >
              <MenuItem value="SELECT" disabled>Select Shipment Mode</MenuItem>
              <MenuItem value="TL">TL</MenuItem>
              <MenuItem value="LTL">LTL</MenuItem>
            </TextField>
          </Stack>
          <Stack gap={2}>
            <Typography
              variant="h2"
              sx={{ "&.MuiTypography-h2": { fontSize: "1rem" }}}
            >
              Selected Shipments
            </Typography>
            <DataGridPro
              columns={combinedShipmentsColumns(handleRemove)}
              rows={shipments}
              getRowId={(item) => item._id}
            />
          </Stack>
          

          <Stack gap={2}>
            <Typography
              variant="h2"
              sx={{ "&.MuiTypography-h2": { fontSize: "1rem" } }}
            >
              Pickups & Stop
            </Typography>
            <LocationsDetails shipment={shipments[0]} />
          </Stack>
        </Stack>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            py: 2,
            gap: 2,
          }}
        >
          <Button sx={{ flex: 1 }} onClick={handleCombine}>
            Combine
          </Button>
          <Button
            sx={{ flex: 1 }}
            color="error"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </StyledPaper>
    </Modal>
  );
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  // backgroundColor: 'red',
  minWidth: "50vw",
  maxWidth: "80vw",

  maxHeight: "80%",
  height: "fit-content",
  padding: "0 1rem",
  borderRadius: "1rem",

  overflowY: "scroll",
}));

const combinedShipmentsColumns = (handleRemove) => [
  {
    field: "actions",
    headerName: "Remove",
    width: 68,
    align: "center",
    renderCell: (params) => (
      <Button onClick={() => handleRemove(params.row._id)}>
        <Close />
      </Button>
    ),
  },
  {
    field: "primaryReference",
    headerName: "Primary Reference",
    minWidth: 140,
  },
  {
    field: "packages",
    headerName: "Packages",
    align: "center",
    renderCell: (params) => params.row.pickups[0].items?.length,
  },
  {
    field: "mode",
    headerName: "Mode",
    align: "center",
    renderCell: (params) => <div>{params.row.mode?.toUpperCase()}</div>,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    align: "center",
    renderCell: (params) => (
      <div className={`cellWithStatus ${params.row.status}`}>
        {params.row.status}
      </div>
    ),
  },
  {
    field: "quantity",
    headerName: "Pallets",
    align: "center",
  },
  {
    field: "weight",
    headerName: "Weight",
    align: "center",
    renderCell: (params)=> <>{params.row.weight} {params.row.weightUOM}</>
  },
  
];
