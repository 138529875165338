import { useNavigate, useParams } from "react-router-dom";
import { useOrderQueries } from "./hooks/useOrderQueries";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { OrderColumns, OrderDetailColumns } from "./components/OrderColumns";
import { Box, Button, Card, Container, Grid, LinearProgress, Typography } from "@mui/material";
import { DataTable } from "src/components/datagrid";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";

export function OrdersPage() {
    const { isOrdersLoading, orders, getOrders } = useOrderQueries();
    const { orderId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getOrders();
    }, []);

    return (
        <>
            <Helmet><title>Orders</title></Helmet>
            <Container
                maxWidth="false"
                sx={{ width: 'fit-content', maxWidth: '100%' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            mb: 1,
                            textAlign: 'center',
                            display: 'flex'
                        }}
                    >
                        Orders
                    </Typography>
                </Box>
                <Grid container item xs={12} justifyContent={'center'}>
                    <Card>
                        <section className="dataTable">
                            <DataTable
                                selectedItemId={orderId}
                                loading={isOrdersLoading}
                                loadData={orders ?? []}
                                columns={OrderColumns}
                                className="datagrid"
                                pagination
                                autoHeight
                                density="compact"
                                disableDensitySelector
                                initState={{
                                    columns: {
                                        columnVisibilityModel: { id: false }
                                    }
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                    loadingOverlay: LinearProgress
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: { disableToolbarButton: true },
                                        quickFilterProps: { debounceMs: 500 },
                                        loadData: orders ?? [],
                                    }
                                }}
                                detailsDrawerTitle="Orders"
                                detailColumns={OrderDetailColumns}
                                modelKey={'orders'}
                                modelGridSetup={{

                                }}
                            />
                        </section>
                    </Card>
                </Grid>

            </Container>
        </>
    );
}