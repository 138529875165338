import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  Tab,
  Typography,
} from "@mui/material";
import { isEqual } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { DataTable } from "src/components/datagrid";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { shipmentDetailColumns } from "src/pages/dashboard/shipmentColumns";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ShipmentDetailsDrawer } from "./components/details-drawer/DetailsDrawer";
import { ShipmentService } from "src/api/shipment.service";
import { WeekCalendar } from "./components/week-calendar/WeekCalendar";
import { VisibilitySwitch } from "./components/VisibilitySwitch";
import { useQuery } from "react-query";
import { useAuth } from "src/hooks/useAuth";
import { dailySummaryColumns } from "./constants/shipmentColumns";

const tabsData = [
  {
    index: "all",
    title: "All",
    iconComponent: null,
    filterFunction: (item) => true,
  },
  {
    index: "inbound",
    title: "Inbound",
    iconComponent: <ArrowDownwardOutlinedIcon />,
  },
  {
    index: "outbound",
    title: "Outbound",
    iconComponent: <ArrowUpwardOutlinedIcon />,
  },
  {
    index: "opened",
    title: "Opened",
    iconComponent: <SwapHorizIcon />,
  },
  {
    index: "closed",
    title: "Closed",
    iconComponent: <DoneAllIcon />,
  },
];

export default function ShipmentsPage() {
  const { shipmentId } = useParams();
  const [params, setSearchParams] = useSearchParams();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const { session } = useAuth();
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(today);

  const [isCalendarVisible, setCalendarVisible] = useState(false);

  const navigate = useNavigate();

  const selectedTabIndex = params.get('tab') ?? 'all';
  const onTabSelected = (event) => {
    debugger;
    setSearchParams({ tab: event.target.innerText.toLowerCase() });
  };

  const onClickCalendarDate = (selectedDay) => {
    setSelectedCalendarDate(selectedDay);
  };

  const service = new ShipmentService(session.token, session.tenant._id);
  // Fetching the data from API
  const {
    data: shipmentsResponse,
    isRefetching: isLoadingMainDataTable,
    refetch: getShipments
  } = useQuery(
    "shipments",
    {
      queryFn: () => service.getShipments(selectedTabIndex),
      enabled: false
    }
  );

  useEffect(() => {
    getShipments()
    // FETCH GET SHIPMENTS EACH MINUTE 
    setInterval(() => {
      getShipments()
    }, 60000)
  }, [])

  useEffect(() => {
    getShipments()
  }, [params])

  // Filtering the data on user actions
  const filteredData = useMemo(() => {
    if (isLoadingMainDataTable || !Array.isArray(shipmentsResponse)) return [];
    if (isCalendarVisible && selectedCalendarDate) {
      return shipmentsResponse.filter((shipment) => {
        const deliveryDate = new Date(
          shipment.stops[0].targetDeliveryLate
        ).setHours(0, 0, 0, 0);

        const shipDate = new Date(
          shipment.pickups[0].targetShipLate
        ).setHours(0, 0, 0, 0);

        const currentDate = selectedCalendarDate;
        return isEqual(deliveryDate, currentDate) || isEqual(shipDate, currentDate);
      });
    }

    return shipmentsResponse;

  }, [shipmentsResponse, selectedCalendarDate, isCalendarVisible])

  // Custom formatter function to format date values
  const formatFilterValue = (filterValue, column) => {
    console.log("test");
    if (column.type === "date" && filterValue?.value) {
      return filterValue.value.toLocaleDateString();
    }
    return filterValue;
  };

  return (
    <>
      <Helmet>
        <title> All Shipments | {session.tenant.title} </title>
      </Helmet>
      <Container maxWidth="false">
        <Typography
          variant="h4"
          sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
        >
          All Shipments
        </Typography>
        <Box sx={{ my: 4, display: { md: "none" } }} />
        <Grid container item xs={12}>
          <Card sx={{ p: 2 }}>
            <VisibilitySwitch
              label="Filter by Day"
              isVisible={!isCalendarVisible}
              setVisibility={setCalendarVisible}
            >
              <WeekCalendar
                startingDate={selectedCalendarDate}
                onClickHandler={onClickCalendarDate}
              />
            </VisibilitySwitch>
            <TabContext value={selectedTabIndex}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <TabList
                  onChange={onTabSelected}
                  aria-label="List of Loads"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {tabsData.map((item, index) => (
                    <Tab
                      icon={item.iconComponent}
                      label={item.title}
                      value={index.toString()}
                      key={index}
                    />
                  ))}
                </TabList>
              </Box>
              <section className="dataTable">
                <DataTable
                  loadData={filteredData}
                  columns={dailySummaryColumns}
                  className="datagrid"
                  density="compact"
                  disableDensitySelector
                  loading={isLoadingMainDataTable}

                  disableMultipleRowSelection={false}

                  // DRAWER PROPS
                  customDrawerComponent={ShipmentDetailsDrawer}
                  selectedItemId={shipmentId}
                  detailColumns={shipmentDetailColumns}
                  onEditTapped={(value) => navigate(`/shipments/${value._id}/edit`)}
                  slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: LinearProgress,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      printOptions: { disableToolbarButton: true },
                      quickFilterProps: { debounceMs: 500 },
                      loadData: filteredData,
                    },
                  }}
                  modelKey='shipments'
                  modelGridSetup={{
                    mobile: mobileSetup,
                    desktop: desktopSetup
                  }}
                />
              </section>
            </TabContext>
          </Card>
        </Grid>
      </Container>
    </>
  );
}

const mobileSetup = {
  "pinnedColumns": {
    "left": [
      "poNumber",
      "primaryReference"
    ],
    "right": ["actions"]
  },
  "columns": {
    "columnVisibilityModel": {
      "poNumber": false,
      "primaryReference": true,
      "mode": true,
      "status": true,
      "created.at": false,
      "integrated": false,
      "quantity": false,
      "weight": false,
      "shipper": false,
      "originCity": false,
      "originLocation": false,
      "pickups[0].targetShipLate": false,
      "receiver": false,
      "destinationCity": false,
      "destinationLocation": false,
      "stops[0].targetDeliveryLate": false,
      "trackingUrl": false,
      "bolFile.url": false,
      "podFile.url": false
    },
    "orderedFields": [
      "poNumber",
      "primaryReference",
      "mode",
      "status",
      "created.at",
      "integrated",
      "quantity",
      "weight",
      "shipper",
      "originCity",
      "originLocation",
      "pickups[0].targetShipLate",
      "receiver",
      "destinationCity",
      "destinationLocation",
      "stops[0].targetDeliveryLate",
      "trackingUrl",
      "bolFile.url",
      "podFile.url"
    ],
    "dimensions": {
      "poNumber": {
        "maxWidth": -1,
        "minWidth": 100,
        "width": 137,
        "flex": 0
      },
      "primaryReference": {
        "maxWidth": -1,
        "minWidth": 120,
        "width": 100
      },
      "mode": {
        "maxWidth": -1,
        "minWidth": 80,
        "width": 100
      },
      "status": {
        "maxWidth": -1,
        "minWidth": 120,
        "width": 100
      },
      "created.at": {
        "maxWidth": -1,
        "minWidth": 150,
        "width": 100
      },
      "integrated": {
        "maxWidth": -1,
        "minWidth": 80,
        "width": 100
      },
      "quantity": {
        "maxWidth": -1,
        "minWidth": 100,
        "width": 100
      },
      "weight": {
        "maxWidth": -1,
        "minWidth": 100,
        "width": 100
      },
      "shipper": {
        "maxWidth": -1,
        "minWidth": 250,
        "width": 100
      },
      "originCity": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "originLocation": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "pickups[0].targetShipLate": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "receiver": {
        "maxWidth": -1,
        "minWidth": 250,
        "width": 100
      },
      "destinationCity": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "destinationLocation": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "stops[0].targetDeliveryLate": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "trackingUrl": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "bolFile.url": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "podFile.url": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      }
    }
  },
  "preferencePanel": {
    "open": false
  },
  "sorting": {
    "sortModel": [
      {
        "field": "created.at",
        "sort": "desc"
      }
    ]
  },
  "pagination": {
    "paginationModel": {
      "page": 0,
      "pageSize": 15
    }
  }
}

const desktopSetup = {
  "pinnedColumns": {
    "left": [
      "poNumber",
      "primaryReference"
    ],
    "right": []
  },
  "columns": {
    "columnVisibilityModel": {},
    "orderedFields": [
      "poNumber",
      "primaryReference",
      "mode",
      "status",
      "created.at",
      "integrated",
      "quantity",
      "weight",
      "shipper",
      "originCity",
      "originLocation",
      "pickups[0].targetShipLate",
      "receiver",
      "destinationCity",
      "destinationLocation",
      "stops[0].targetDeliveryLate",
      "trackingUrl",
      "bolFile.url",
      "podFile.url"
    ],
    "dimensions": {
      "poNumber": {
        "maxWidth": -1,
        "minWidth": 100,
        "width": 137,
        "flex": 0
      },
      "primaryReference": {
        "maxWidth": -1,
        "minWidth": 120,
        "width": 100
      },
      "mode": {
        "maxWidth": -1,
        "minWidth": 80,
        "width": 100
      },
      "status": {
        "maxWidth": -1,
        "minWidth": 120,
        "width": 100
      },
      "created.at": {
        "maxWidth": -1,
        "minWidth": 150,
        "width": 100
      },
      "integrated": {
        "maxWidth": -1,
        "minWidth": 80,
        "width": 100
      },
      "quantity": {
        "maxWidth": -1,
        "minWidth": 100,
        "width": 100
      },
      "weight": {
        "maxWidth": -1,
        "minWidth": 100,
        "width": 100
      },
      "shipper": {
        "maxWidth": -1,
        "minWidth": 250,
        "width": 100
      },
      "originCity": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "originLocation": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "pickups[0].targetShipLate": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "receiver": {
        "maxWidth": -1,
        "minWidth": 250,
        "width": 100
      },
      "destinationCity": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "destinationLocation": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "stops[0].targetDeliveryLate": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "trackingUrl": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "bolFile.url": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      },
      "podFile.url": {
        "maxWidth": -1,
        "minWidth": 200,
        "width": 100
      }
    }
  },
  "filter": {
    "filterModel": {
      "items": [],
      "logicOperator": "and",
      "quickFilterValues": [],
      "quickFilterLogicOperator": "and"
    }
  },
  "sorting": {
    "sortModel": [
      {
        "field": "created.at",
        "sort": "desc"
      }
    ]
  },
  "pagination": {
    "paginationModel": {
      "page": 0,
      "pageSize": 15
    }
  }
}