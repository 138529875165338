import { Box, Divider } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { useEffect } from "react";

function CustomToolbar({ loadData, ...props }) {
  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: props.CustomToolbarActions? "100%" : "auto",
        }}
      >
        { props.CustomToolbarActions ? <props.CustomToolbarActions /> : null } 
        <Box>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport />
        </Box>
      </Box>
      <Box >
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          
        />
      </Box>
    </GridToolbarContainer>
  );
}

CustomToolbar.propTypes = {
  loadData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CustomToolbar;
