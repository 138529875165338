import { Box, Grid, ListItem, Typography } from "@mui/material";
import { Order } from "src/models/order";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";

export function OrderDetailListItem(props) {
  const { order, customProps } = props;

  const {
    mapFields: fieldMapper,
    items: { fields: items },
  } = useShipmentFormContext();

  function handleOrderSelection() {
    const shipmentAux = Order.responseToShipment(order);
    fieldMapper(shipmentAux);
  }

  return (
    <ListItem {...customProps} onClick={(event)=> {
      customProps.onClick(event)
      handleOrderSelection()
    }}>
      <Grid container alignItems="center">
        <Grid
          item
          sx={{ width: "100%" }}
        >
          <Typography
            sx={{ width: "100%" }}
            variant="body2"
            fontWeight={600}
          >
            {order.VENDOR} {order.PO_NUM}-{order.RELEASE_NUM}
          </Typography>
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" color="text.secondary" fontWeight={600}>
              Vendor
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textOverflow: "ellipsis", overflow: 'hidden' }} noWrap>
              {order.VENDOR_NM}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              // display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" color="text.secondary" noWrap fontWeight={600}>   
              Ship to
            </Typography>
            <Typography variant="body2" color="text.secondary"  sx={{ textOverflow: "ellipsis", overflow: 'hidden' }} noWrap>
              {order.SHIP_TO_LOCATION}
            </Typography>
          </Box>
          {order.SHIPMENT_NUM && <Box
            sx={{
              width: "100%",
              display: 'flex',
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" color="text.secondary" fontWeight={600}>
              Shipment Num
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textOverflow: "ellipsis", overflow: 'hidden' }} noWrap>
              {order.SHIPMENT_NUM}
            </Typography>
          </Box>}
        </Grid>
      </Grid>
    </ListItem>
  );
}
