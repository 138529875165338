import { DeleteOutlineOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { useNavigate } from "react-router-dom";
import { useShipmentDetails } from "../context/ShipmentDetailsContext";
import { useDeletion } from "../hooks/useDeletion";

export function DeleteShipmentButton({ allowDeletion, ...props }) {
  const { deleteShipment, isDeletionLoading, shipment } = useShipmentDetails();
  const { handleOpenClickDeletionDialog, DeletionDialog } =
    useDeletion(handleDeleteShipment);

  const callback = props.deletionCallback ? props.deletionCallback : null;

  const navigate = useNavigate();

  function handleDeleteShipment() {
    deleteShipment(shipment._id);
    const deletionCallback = () => {
      navigate("/");
      callback && callback();
    };

    setTimeout(deletionCallback, 3000);
  }

  if (allowDeletion) {
    return (
      <>
        <LoadingButton
          variant="text"
          fullWidth
          type="button"
          color="error"
          onClick={handleOpenClickDeletionDialog}
          loading={isDeletionLoading}
          sx={{
            py: "0.8rem",
            lineHeight: 1.5,
            opacity: 0.7,
            width: "100%",
          }}
          startIcon={<DeleteOutlineOutlined />}
        >
          Delete Shipment
        </LoadingButton>
        {DeletionDialog}
      </>
    );
  }
}
