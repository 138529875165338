import { useMutation, useQuery } from "react-query";
import { LocationService } from "src/api/location/location.service";
import { VendorService } from "src/api/vendor.service";
import { WarehouseService } from "src/api/warehouse.service";
import { useAuth } from "src/hooks/useAuth"

export const useLocationQueries = () => {
    const { session } = useAuth();
    const service = new LocationService(session.token, session.tenant._id);
    const warehouseService = new WarehouseService(session.token, session.tenant._id);
    const vendorService = new VendorService(session.token, session.tenant._id);

    const { refetch: getLocations, isLoading: isLocationsLoading, data: locations } = useQuery(
        'locations',
        { 
            queryFn: async () => { return service.getAll(); },
            enabled: false
        },
    );

    let refType = null;
    const { refetch: refetchReferences, isLoading: isReferencesLoading, data: references } = useQuery(
        'references',
        {
            queryFn: async () => {
                if (refType === 'Warehouse') return warehouseService.getWarehouses();
                else if (refType === 'Vendor') return vendorService.getAll();
            },
            enabled: false
        }
    )

    const getReferences = (type) => {
        refType = type;
        refetchReferences();
    }
    
    let locationId = null;
    const { refetch: refetchLocation, isLoading: isLocationLoading, data: location } = useQuery({
            queryFn: async () => service.getById(locationId),
            enabled: false
        });
    
    const getLocation = (id) => {
        locationId = id;
        refetchLocation(locationId);
    }

    const { mutate: create, isLoading: isCreating } = useMutation({
        mutationFn: async ({ location, onCreated, onFailed }) => service
            .create(location)
            .then(data => onCreated(data))
            .catch(error => onFailed(error.message))
    });

    const { mutate: update, isLoading: isUpdating } = useMutation({
        mutationFn: async ({ location, onCreated, onFailed }) => service
            .update(location)
            .then(data => onCreated(data))
            .catch(error => onFailed(error))
    })

    return {
        getLocations,
        isLocationsLoading,
        locations,

        getLocation,
        isLocationLoading,
        location,

        create,
        isCreating,

        getReferences,
        isReferencesLoading,
        references,
        
        update,
        isUpdating
    }
}