import { Box, Stack, Typography } from "@mui/material";

import { LocationDetails } from "src/components/location-details";

import { fDateString } from "src/utils/formatTime";

import { Location } from "src/models/location";
import { shipmentServices } from "../../constants/services";
import { valueGetter } from "./DetailsDrawer";
import { ServiceField, ServicesDetails } from "./ServicesDetails";

export function LocationsDetails({ shipment }) {
  return (
    <>
      {/* PICKUP & STOP */}
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          width: "100%",
          gap: "0.75rem",
        }}
      >
        <LocationDetails
          locationLabel={"Origin"}
          location={new Location().fromPickup(shipment.pickups[0])}
          type={"pickup"}
          customFields={
            <Stack>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Target Ship Early</Typography>
                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(
                    fDateString(shipment.pickups[0].targetShipEarly)
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Target Ship Late</Typography>
                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(fDateString(shipment.pickups[0].targetShipLate))}
                </Typography>
              </Box>
              <Typography fontWeight={600} mt={1}>
                Contact Info
              </Typography>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Contact Name</Typography>

                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(shipment.pickups[0].originContact)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Phone</Typography>

                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(shipment.pickups[0].originPhone)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>E-Mail</Typography>

                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(shipment.pickups[0].originEmail)}
                </Typography>
              </Box>
              <Box py={1}>
                <Typography fontWeight={600}>Services</Typography>
                <Box>
                  {shipmentServices
                    .find(
                      (servicePerMode) => servicePerMode.mode == shipment.mode
                    )
                    ?.pickups.map((service, index) => {
                      console.log(service.name);
                      const [category, key] = service.name.split(".");

                      return (
                        <ServiceField
                          service={service}
                          key={service.name + index}
                          checked={
                            shipment?.[category]
                              ? shipment?.[category][key]
                              : false
                          }
                        />
                      );
                    })}
                </Box>
              </Box>
            </Stack>
          }
        />
        <LocationDetails
          locationLabel={"Destination"}
          location={new Location().fromStop(shipment.stops[0])}
          type={"stop"}
          customFields={
            <Stack>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Target Delivery Early</Typography>
                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(
                    fDateString(shipment.stops[0].targetDeliveryEarly)
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Target Delivery Late</Typography>
                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(
                    fDateString(shipment.stops[0].targetDeliveryLate)
                  )}
                </Typography>
              </Box>
              <Typography fontWeight={600} mt={1}>
                Contact Info
              </Typography>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Contact Name</Typography>

                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(shipment.stops[0].destContact)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>Phone</Typography>

                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(shipment.stops[0].destPhone)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    xs: "block",
                  },
                  justifyContent: "space-between",
                  py: "0.25rem",
                  gap: "0.5rem",
                }}
              >
                <Typography>E-Mail</Typography>

                <Typography
                  fontWeight={600}
                  textAlign={{
                    md: "right",
                    xs: "left",
                  }}
                >
                  {valueGetter(shipment.stops[0].destEmail)}
                </Typography>
              </Box>

              <Box py={1}>
                <Typography fontWeight={600}>Services</Typography>
                <Box>
                  {shipmentServices
                    .find(
                      (servicePerMode) => servicePerMode.mode == shipment.mode
                    )
                    ?.stops.map((service, index) => {
                      console.log(service.name);
                      const [category, key] = service.name.split(".");

                      return (
                        <ServiceField
                          service={service}
                          key={service.name + index}
                          checked={
                            shipment?.[category]
                              ? shipment?.[category][key]
                              : false
                          }
                        />
                      );
                    })}
                </Box>
              </Box>
            </Stack>
          }
        />
      </Box>
      <ServicesDetails shipment={shipment} />
    </>
  );
}
