export class Location {
  name = null;
  addr1 = null;
  addr2 = null;
  city = null;
  state = null;
  zip = null;
  ctry = "USA";
  timezone = null;

  static toJson(location) {
    if (!location.refType || !location.refId) {
      location.refType = null;
      location.refId = null;
    }

    return location;
  }

  fromWarehouse(warehouse) {
    this.name = warehouse.name;
    this.addr1 = warehouse.location;
    this.zip = warehouse.zip;
    this.city = warehouse.city;
    this.timezone = warehouse.timezone;
    this.state = warehouse.state;
    // this.addr1 = warehouse.address;

    return this;
  }

  fromStop(stop) {
    this.name = `Receiver: ${stop.destName}`;
    this.addr1 = stop.destAddr1;
    this.addr2 = stop.destAddr2;
    this.city = stop.destCity;
    this.state = stop.destState;
    this.zip = stop.destZip;
    if (!!stop.destCtry && stop.destCtry != this.ctry) {
      this.ctry = stop.destCtry;
    }
    return this;
  }

  fromPickup(pickup) {
    this.name = `Shipper: ${pickup.originName}`;
    this.addr1 = pickup.originAddr1;
    this.addr2 = pickup.originAddr2;
    this.city = pickup.originCity;
    this.state = pickup.originState;
    this.zip = pickup.originZip;
    if (!!pickup.originCtry && pickup.originCtry != this.ctry) {
      this.ctry = pickup.originCtry;
    }
    return this;
  }

  static toShipment(location, type) {
    const shipment = {}

    if (type == "pickup") {
      shipment.pickups = [{}]
      shipment.pickups[0].originName = location.name ? location.name : "";
      shipment.pickups[0].originAddr1 = location.addr1 ? location.addr1 : location.location ? location.location : "";
      shipment.pickups[0].originAddr2 = location.addr2 ? location.addr2 : "";
      shipment.pickups[0].originCity = location.city ? location.city : "";
      shipment.pickups[0].originState = location.state ? location.state : "";
      shipment.pickups[0].originCtry = location.ctry ? location.ctry : "";
      shipment.pickups[0].originZip = location.zip ? location.zip : "";
      shipment.pickups[0].timezone = location.timezone ? location.timezone : "";

      shipment.pickups[0].targetShipEarly = location.targetEarly ? location.targetEarly : false;
      shipment.pickups[0].targetShipLate = location.targetLate ? location.targetLate : false;

      if (location.contacts?.length) {
        shipment.pickups[0].originContact = location.contacts[0].name ? location.contacts[0].name : "";
        shipment.pickups[0].originPhone = location.contacts[0].phone ? location.contacts[0].phone : "";
        shipment.pickups[0].originEmail = location.contacts[0].email ? location.contacts[0].email : "";
      }
    } else if (type == "stop") {
      shipment.stops = [{}]
      shipment.stops[0].destName = location.name ? location.name : "";
      shipment.stops[0].destAddr1 = location.addr1 ? location.addr1 : "";
      shipment.stops[0].destAddr2 = location.addr2 ? location.addr2 : "";
      shipment.stops[0].destCity = location.city ? location.city : "";
      shipment.stops[0].destState = location.state ? location.state : "";
      shipment.stops[0].destCtry = location.ctry ? location.ctry : "";
      shipment.stops[0].destZip = location.zip ? location.zip : "";
      shipment.stops[0].timezone = location.timezone ? location.timezone : "";

      shipment.stops[0].targetDeliveryEarly = location.targetEarly ? location.targetEarly : false;
      shipment.stops[0].targetDeliveryLate = location.targetLate ? location.targetLate : false;

      if (location.contacts?.length) {
        shipment.stops[0].destContact = location.contacts[0].name ? location.contacts[0].name : "";
        shipment.stops[0].destPhone = location.contacts[0].phone ? location.contacts[0].phone : "";
        shipment.stops[0].destEmail = location.contacts[0].email ? location.contacts[0].email : "";
      }
    }
    return shipment
  }
}