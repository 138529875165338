import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Checkbox, FormControlLabel, Grid, Tab } from "@mui/material";
import { useState } from "react";
import { shipmentServices } from "../../constants/services";

export function ServicesDetails({ shipment }) {
  const [selectedServiceTab, setSelectedServiceTab] = useState(shipment.mode);

  function ServicesTabs() {
    return (
      <TabList>
        {shipment.mode == "LTL" ? (
          <Tab
            value={"LTL"}
            key={"LTL"}
            label={"Services"}
            onClick={() => setSelectedServiceTab("LTL")}
          />
        ) : (
          shipment.mode == "TL" && (
            <Tab
              value={"TL"}
              key={"TL"}
              label={"Equipment"}
              disabled={!shipment.mode == "TL"}
              onClick={() => setSelectedServiceTab("TL")}
            />
          )
        )}
      </TabList>
    );
  }

  return (
    <>
      <Box>
        <TabContext value={selectedServiceTab}>
          {/* TABS */}
          <ServicesTabs />

          {/* SERVICE TABS CONTENT */}
          <TabPanel value="LTL">
            <Grid
              container
              columns={{ xs: 3, sm: 6, md: 9 }}
              columnSpacing={3}
              rowSpacing={1}
            >
              {shipmentServices
                .find((servicePerMode) => servicePerMode.mode == "LTL")
                ?.services.map((service, index) => {
                  const [ category, key ] = service.name.split('.')
                  return (
                    <Grid item xs={3}>
                      <ServiceField
                        service={service}
                        checked={shipment?.[category] ? shipment?.[category][key] : false}
                        key={`${service.name}${index}`}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </TabPanel>

          <TabPanel value="TL">
            <Grid
              container
              columns={{ xs: 3, sm: 6, md: 9 }}
              columnSpacing={3}
              rowSpacing={1}
            >
              {shipmentServices
                .find((servicePerMode) => servicePerMode.mode == "TL")
                ?.services.map((service, index) => {
                  const [ category, key ] = service.name.split('.')
                  return (
                    <Grid item xs={3}>
                      <ServiceField
                        service={service}
                        checked={shipment?.[category] ? shipment?.[category][key] : false}
                        key={`${service.name}${index}`}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </TabPanel>

          <TabPanel
            value="0"
            sx={{ "&.MuiTabPanel-root": { display: "none" } }}
          />
        </TabContext>
      </Box>
    </>
  );
}

export function ServiceField({ service, checked }) {
  return (
    <FormControlLabel
      sx={{ width: "100%", margin: 0 }}
      control={<Checkbox checked={!!checked}/>}
      label={service.label}
    />
  );
}
