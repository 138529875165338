import { Location } from "./location";

const cityStateZipREGEX = /^(.*),\s*([A-Z]{2})\s*([\d-]+)$/;
export class Order {
  static responseToShipment(orderResponse) {
    const [, city, state, zip] =
      orderResponse.CITY_ST_ZIP.match(cityStateZipREGEX);

    function getStop() {
      if (orderResponse.stop) {
        return {
          ...Location.toShipment(orderResponse.stop, "stop"),
        };
      } else {
        return {
          stops: [
            {
              destName: orderResponse.SHIP_TO_LOCATION ?? "",
              destAddr1: orderResponse.ADDRESS1 ?? "",
              destAddr2: orderResponse.ADDRESS2 ?? "",
              destCity: city,
              destState: state,
              destZip: zip,
            },
          ],
        };
      }
    }

    function getPickup() {
      if (orderResponse.pickup) {
        return {
          ...Location.toShipment(orderResponse.pickup, "pickup"),
        };
      } else {
        return {
          pickups: [
            {
              originName: orderResponse.VENDOR_NM ?? "",
            },
          ],
        };
      }
    }

    return {
      shipmentNumber: [orderResponse.SHIPMENT_NUM ?? ""],
      items: [
        {
          itemId: orderResponse.ITEM_NUM ?? "",
          description: orderResponse.ITEM_DESC ?? "",        
          poNumber: `${orderResponse.PO_NUM}-${orderResponse.RELEASE_NUM ?? 0}`
        },
      ],
      ...getPickup(),
      ...getStop(),
    };
  }
}
