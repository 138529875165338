import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  Typography,
  Button
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { DataTable } from "src/components/datagrid";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { columns } from "./columns";
import { detailColumns } from "./detailColumns";
import { WarehouseService } from "src/api/warehouse.service";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { WareshouseDetailsDrawer } from "./components/DetailsDrawer";

export function WarehousesPage() {
  const { session } = useAuth();
  const [apiData, setApiData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const navigate = useNavigate();
  const auth = session.permissions;

  const service = new WarehouseService(session.token, session.tenant._id);
  const { warehouseId } = useParams();

  // Fetching the data from API
  useEffect(() => {
    (async () => {
      if (session.token) {
        try {
          setIsLoadingData(true);
          const result = await service.getWarehouses();
          setApiData(result);
        } catch (exception) {
          console.error(
            `An error ocurred on fetching loads data: ${exception}`
          );
        }
        finally {
          setIsLoadingData(false);
        }
      }
    })();
  }, [session.token]);

  const AddButton = () => {
    if (!auth.IsBackOffice)
      return null;

    return (
      <Button size="small" variant="outlined" onClick={() => navigate("/warehouses/create") }
        sx={{ mb: 1, textTransform: "capitalize" }}>
        + Add Warehouse
      </Button>
    );
  }

  return (
    <>
      <Helmet>
        <title>Warehouses</title>
      </Helmet>
      <Container maxWidth="false" sx={{ width: 'fit-content', maxWidth: '100%'}}>
        <Box sx={{display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h4" sx={{ mb: 1, textAlign: "center", display: "flex" }}>
            Warehouses
          </Typography>
          <AddButton />
        </Box>
        <Grid container item xs={12} justifyContent={'center'}>
          <Card>
            <section className="dataTable">
              <DataTable
                detailsDrawerTitle="Warehouse"
                loadData={apiData}
                columns={columns}
                className="datagrid"
                pagination
                autoHeight
                density="compact"
                // disableColumnFilter
                // disableColumnSelector
                disableDensitySelector
                loading={isLoadingData}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                slots={{
                  toolbar: CustomToolbar,
                  loadingOverlay: LinearProgress,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    quickFilterProps: { debounceMs: 500 },
                    loadData: apiData,
                  },
                }}
                detailColumns={detailColumns}
                customDrawerComponent={WareshouseDetailsDrawer}
                onEditTapped={(value) => navigate(`/warehouses/${value._id}/edit`)}
                selectedItemId={warehouseId}
                modelKey={'warehouses'}
                modelGridSetup={{
                  desktop: desktopSetup,
                  mobile: mobileSetup
                }}
              />
            </section>
          </Card>
        </Grid>
      </Container>
    </>
  );
}

const mobileSetup = {
  "pinnedColumns": {},
  "columns": {
      "columnVisibilityModel": {
          "city": false,
          "state": false,
          "zip": false,
          "docks": false
      },
      "orderedFields": [
          "name",
          "location",
          "city",
          "state",
          "zip",
          "docks"
      ],
      "dimensions": {
          "name": {
              "maxWidth": -1,
              "minWidth": 250,
              "width": 100
          },
          "location": {
              "maxWidth": -1,
              "minWidth": 230,
              "width": 100
          },
          "city": {
              "maxWidth": -1,
              "minWidth": 150,
              "width": 100
          },
          "state": {
              "maxWidth": -1,
              "minWidth": 50,
              "width": 100
          },
          "zip": {
              "maxWidth": -1,
              "minWidth": 70,
              "width": 100
          },
          "docks": {
              "maxWidth": -1,
              "minWidth": 70,
              "width": 100
          }
      }
  },
  "preferencePanel": {
      "open": false
  },
  "filter": {
      "filterModel": {
          "items": [],
          "logicOperator": "and",
          "quickFilterValues": [],
          "quickFilterLogicOperator": "and"
      }
  },
  "sorting": {
      "sortModel": []
  },
  "pagination": {
      "paginationModel": {
          "page": 0,
          "pageSize": 15
      }
  }
}

const desktopSetup = {
  "columns": {
      "columnVisibilityModel": {},
      "orderedFields": [
          "name",
          "location",
          "city",
          "state",
          "zip",
          "docks"
      ],
      "dimensions": {
          "name": {
              "maxWidth": -1,
              "minWidth": 250,
              "width": 100
          },
          "location": {
              "maxWidth": -1,
              "minWidth": 230,
              "width": 100
          },
          "city": {
              "maxWidth": -1,
              "minWidth": 150,
              "width": 100
          },
          "state": {
              "maxWidth": -1,
              "minWidth": 50,
              "width": 100
          },
          "zip": {
              "maxWidth": -1,
              "minWidth": 70,
              "width": 100
          },
          "docks": {
              "maxWidth": -1,
              "minWidth": 70,
              "width": 100
          }
      }
  },
  "preferencePanel": {
      "open": false
  },
  "filter": {
      "filterModel": {
          "items": [],
          "logicOperator": "and",
          "quickFilterValues": [],
          "quickFilterLogicOperator": "and"
      }
  },
  "sorting": {
      "sortModel": []
  },
  "pagination": {
      "paginationModel": {
          "page": 0,
          "pageSize": 15
      }
  }
}