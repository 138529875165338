import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined, ControlPointDuplicate, Edit } from "@mui/icons-material";
import { Box, Button, Link, Tooltip } from "@mui/material";
import { Compare } from "src/utils/compare";
import { getEquipmentFromShipment } from "src/utils/shipment";

export const backofficeShipmentColumns = () => {

    return [
        {
            field: "poNumber",
            headerName: "PO Number",
            minWidth: 100,
        },
        {
            field: "primaryReference",
            headerName: "Primary Reference",
            minWidth: 120,
            valueGetter: (params) => {
                /// Merge original primary references for combined shipments for users be able to search for.
                const shipment = params.row;
                const result = `${shipment.primaryReference}, ${shipment.combinedFrom?.map((combined) => combined.primaryReference).join(', ') ?? ''}`;
                return result;
            },
            renderCell: (params) => <div>{params.row.primaryReference}</div>,
        },
        {
            field: "mode",
            headerName: "Mode",
            minWidth: 80,
            align: 'center',
            renderCell: (params) => <div>{params.row.mode?.toUpperCase()}</div>,
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 120,
            align: 'center',
            renderCell: (params) => <div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>,
        },
        {
            field: "customer",
            headerName: "Customer",
            minWidth: 150,
            align: 'center',
            renderCell: (params) => <div>{params.row.tenant?.name}</div>,
            valueGetter: (params) => params.row.tenant?.name,
        },
        {
            field: "created.at",
            headerName: "Created At",
            minWidth: 150,
            align: 'center',
            renderCell: (params) => <div>{fDateString(params.row.created?.at)}</div>,
            valueGetter: (params) => params.row.created?.at,
            sortComparator: Compare.dayInMoth,
        },
        {
            field: "created.by",
            headerName: "Created By",
            minWidth: 130,
            align: 'center',
            renderCell: (params) => {
                const by = params.row.created?.by;

                if (!by) return <>--</>
                return <>{`${by?.firstname} ${by?.lastname}`}</>
            },
            valueGetter: (params) => {
                const by = params.row.created?.by;
                return `${by?.firstname} ${by?.lastname}`;
            },
        },
        {
            field: "integrated",
            headerName: "Confirmed",
            minWidth: 80,
            align: 'center',
            renderCell: (params) => params.row.integrated
                ? <CheckBoxOutlined sx={{ opacity: 0.8 }} /> : <CheckBoxOutlineBlankOutlined sx={{ opacity: 0.8 }} />,
        },
        {
            field: "quantity",
            headerName: "Pallets",
            minWidth: 100,
        },
        {
            field: "weight",
            headerName: "Weight",
            minWidth: 100,
        },
        {
            field: "equipment",
            headerName: "Equipment",
            minWidth: 100,
            valueGetter: (params) => getEquipmentFromShipment(params.row) ?? '--',
        },
        {
            field: "carrier",
            headerName: "Carrier",
            minWidth: 100,
            valueGetter: (params) => getCarrierShipment(params.row),
        },        
        {
            field: "pickups.originName",
            headerName: "Shipper",
            minWidth: 250,
            renderCell: (params) => <div>{params.row.pickups[0]?.originName}</div>,
            valueGetter: (params) => params.row.pickups[0]?.originName,
        },
        {
            field: "pickups.originCity",
            headerName: "Origin City",
            minWidth: 200,
            renderCell: (params) => <div>{params.row.pickups[0]?.originCity}</div>,
            valueGetter: (params) => params.row.pickups[0]?.originCity,
        },
        {
            field: "pickups.originAddr1",
            headerName: "Origin Adress",
            minWidth: 200,
            renderCell: (params) => <div>{params.row.pickups[0]?.originAddr1}</div>,
            valueGetter: (params) => params.row.pickups[0]?.originAddr1,
        },
        {
            field: "pickups.targetShipLate",
            headerName: "Ship Date",
            minWidth: 200,
            renderCell: (params) => {
                <div>{fDateString(params.row.pickups[0]?.targetShipLate)}</div>
            },
            // PASSES TO valueFormatter
            valueGetter: (params) => {
                return fDateString(params.row.pickups[0]?.targetShipLate);
            },
        },
        {
            field: "stops.destName",
            headerName: "Receiver",
            minWidth: 250,
            renderCell: (params) => <div>{params.row.stops[0]?.destName}</div>,
            valueGetter: (params) => params.row.stops[0]?.destName,
        },
        {
            field: "stops.destCity",
            headerName: "Destination City",
            minWidth: 200,
            renderCell: (params) => <div>{params.row.stops[0]?.destCity}</div>,
            valueGetter: (params) => params.row.stops[0]?.destCity,
        },
        {
            field: "stops.destAddr1",
            headerName: "Dest. Address",
            minWidth: 200,
            renderCell: (params) => <div>{params.row.stops[0]?.destAddr1}</div>,
            valueGetter: (params) => params.row.stops[0]?.destAddr1,
        },
        {
            field: "stops.targetDeliveryLate",
            headerName: "Delivery Date",
            minWidth: 200,
            // PASSES TO valueFormatter
            renderCell: (params) => {
                return fDateString(params.row.stops[0]?.targetDeliveryLate);
            },
            valueGetter: (params) => {
                return fDateString(params.row.pickups[0]?.targetShipLate);
            },
        },
        {
            field: "trackingUrl",
            headerName: "Tracking URL",
            minWidth: 200,
            renderCell: (params) => params.row.trackingUrl ? <Button href={params.row.trackingUrl}>Tracking</Button> : <Unavailable />,
        },
        {
            field: "bolFile.url",
            headerName: "BOL",
            minWidth: 200,
            renderCell: (params) => params.row.bolFile ? <Link href={params.row.bolFile?.url}>Download BOL</Link> : '',
        },
        {
            field: "podFile.url",
            headerName: "POD",
            minWidth: 200,
            renderCell: (params) => params.row.podFile ? <Link href={params.row.podFile?.url}>Download POD</Link> : '',
        },
        {
            field: "integrations.metadata.bolNumber",
            headerName: "BOL Number",
            minWidth: 200,
            valueGetter: (params) => getBOLNumber(params.row),
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Box>
                        <Tooltip title="Duplicate">
                            <Button onClick={
                                (e) => {
                                    e.stopPropagation();
                                }}
                                href={`/shipments/${params.row._id}/edit?duplicate`}
                            >
                                <ControlPointDuplicate />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Button
                                onClick={(e) => e.stopPropagation()}
                                href={`/shipments/${params.row._id}/edit`}
                            ><Edit /></Button>
                        </Tooltip>
                    </Box>
                )
            },
        }
    ];
}

const Unavailable = () => (
    <Button disabled sx={{ fontWeight: 200 }}>Unavailable</Button>
)

const getBOLNumber = (shipment) => {
    const BOLNumber = shipment.integrations?.find(integration => (
        integration.target == 'banyan' &&
        integration.status == 'success' &&
        !!integration?.metadata?.bolNumber
    )
    )?.metadata.bolNumber;
    return BOLNumber || ''
}

const getCarrierShipment = (shipment) => {
    if (shipment.mode === 'LTL') {
        const carrier = shipment.integrations?.find(integration => (integration.status == 'success'));
        return carrier?.metadata?.carrier ?? '--';
    } else {
        return 'Trinity Logistic';
    }    
}

const fDateString = (dateString) => {
    if (!dateString) return ' -- '
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${month}/${day}/${year} ${hours}:${minutes}`;
};